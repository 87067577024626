import { useTranslation } from "react-i18next";
import { ApplicationRoutes } from "routes";

export const useRouteDefinitionGenerator: () => { getRoutes: () => ApplicationRoutes } = () => {
    const { t } = useTranslation();

    return {
        getRoutes: () => ({
            index: t("routes.index.route"),
            challengeOverview: t("routes.challengeOverview.route"),
            makeChallenge: t("routes.makeChallenge.route"),
            updateChallenge: t("routes.updateChallenge.route")
        })
    };
};