import { Box, Container, Flex, Heading, Select, Stack, Text } from "@chakra-ui/react";
import classRoom from "assets/images/classRoom.png";
import schoolBuilding from "assets/images/schoolBuilding.png";
import { ChallengeTypeBox } from "components/challengeType/challengeTypeBox";
import { useGetClassRoomsQuery } from "pages/makeChallenge/models/getClassRooms";
import { useGetSchoolsQuery } from "pages/makeChallenge/models/getSchools";
import { ChangeEventHandler, useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";

export enum ChallengeType {
    SCHOOL = "COMBINED",
    CLASS = "SEPARATE"
}

interface Props {
    title: string;
    description: string;
    instructions: string;

    defaultValue?: [ChallengeType, string, string[]];

    onChange: (data:[string, string, string[]]) => void;
}

export const TypeStep = (props: Props) => {
    const { t } = useTranslation();
    const { description, instructions, defaultValue, onChange } = props;

    const [ selectedChallenge, setSelectedChallenge ] = useState<ChallengeType>(defaultValue ? defaultValue[0] : ChallengeType.SCHOOL);

    const [ now ] = useState(new Date().toISOString());
    const [ selectedSchoolId, setSelectedSchoolId ] = useState(defaultValue ? defaultValue[1] : "");
    const [ selectedClassRoomId, setSelectedClassroomId ] = useState(defaultValue ? defaultValue[2][0] : "");

    const { data: schools } = useGetSchoolsQuery({ variables: { now } });
    const { data: classRooms } = useGetClassRoomsQuery({ variables: { now } });

    useEffect(() => {
        if (selectedChallenge === ChallengeType.SCHOOL) {
            processSelectedSchool();
            return;
        }

        if (selectedChallenge === ChallengeType.CLASS) {
            processSelectedClass();
            return;
        }
    }, [selectedChallenge, selectedSchoolId, selectedChallenge, selectedClassRoomId]);

    useEffect(() => {
        if (schools && schools.schools.length && !selectedSchoolId) {
            setSelectedSchoolId(schools.schools[0].id);
        }
    }, [ schools ]);

    useEffect(() => {
        if (classRooms && classRooms.classes.length && !selectedClassRoomId) {
            setSelectedClassroomId(classRooms.classes[0].id);
        }
    }, [ classRooms ]);

    const onSchoolChange: ChangeEventHandler<HTMLSelectElement> = (event) => {
        const schoolId = event.target.value;
        setSelectedSchoolId(schoolId);
    };

    const onClassRoomChange: ChangeEventHandler<HTMLSelectElement> = (event) => {
        const classRoomId = event.target.value;
        setSelectedClassroomId(classRoomId);
    };

    const processSelectedSchool = () => {
        const classRoomIds = classRooms?.classes.filter(classRoom => classRoom.school.id === selectedSchoolId).map(classRoom => classRoom.id);

        if (!selectedChallenge) {
            return;
        }

        onChange([selectedChallenge.toString(), selectedSchoolId, (classRoomIds || [])]);
    };

    const processSelectedClass = () => {
        const classRoom = (classRooms?.classes || []).find(classRoom => classRoom.id === selectedClassRoomId);
        const school = (schools?.schools || []).find((school) => school.id === classRoom?.school.id);

        if (!school || !school.id || !selectedClassRoomId || !selectedChallenge) {
            return;
        }

        onChange([selectedChallenge.toString(), school.id, [selectedClassRoomId]]);
    };

    return (
        <Box>
            <Container
                maxWidth="7xl"
                py={16}
                as={Stack}
                spacing={12}
            >
                <Stack>
                    <Heading> {description} </Heading>
                    <Text> {instructions} </Text>
                </Stack>
                <Stack
                    direction={{ base: "column", md: "row" }}
                    spacing={{ base: 10, md: 4, lg: 10 }}
                >

                    <ChallengeTypeBox
                        title={t("makeChallengePage.schoolType.title")}
                        icon={schoolBuilding}
                        instructions={<Trans i18nKey="makeChallengePage.schoolType.description" components={{ strong: <strong /> }} />}
                        onClick={() => setSelectedChallenge(ChallengeType.SCHOOL)}
                        isActive={selectedChallenge === ChallengeType.SCHOOL}
                    />
                    <ChallengeTypeBox
                        title={t("makeChallengePage.classType.title")}
                        icon={classRoom}
                        instructions={<Trans i18nKey="makeChallengePage.classType.description" components={{ strong: <strong /> }} />}
                        onClick={() => setSelectedChallenge(ChallengeType.CLASS)}
                        isActive={selectedChallenge === ChallengeType.CLASS}
                    />

                </Stack>
            </Container>

            <Container
                maxWidth="7xl"
                py={16}
                as={Stack}
                spacing={12}
            >
                <Flex
                    flexDirection="column"
                    hidden={selectedChallenge !== ChallengeType.SCHOOL}
                >
                    <Text>School</Text>
                    <Select
                        onChange={onSchoolChange}
                        value={selectedSchoolId}
                    >
                        {
                            (schools?.schools || []).map((school) => (
                                <option 
                                    value={school.id} 
                                    key={school.id}
                                >
                                    {school.title}
                                </option>
                            ))
                        }
                    </Select>
                </Flex>

                <Flex
                    flexDirection="column"
                    hidden={selectedChallenge !== ChallengeType.CLASS}
                >
                    <Text>Klas</Text>
                    <Select
                        onChange={onClassRoomChange}
                        value={selectedClassRoomId}
                    >
                        {
                            (classRooms?.classes || []).map((classRoom) => (
                                <option 
                                    value={classRoom.id}
                                    key={classRoom.id}
                                >
                                    {classRoom.school.title} - {classRoom.title}
                                </option>
                            ))
                        }
                    </Select>
                </Flex>
            </Container>
        </Box>
    );
};