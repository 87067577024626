import { Heading, Image, Text } from "@chakra-ui/react";
import confetti from "canvas-confetti";
import confettiFive from "assets/images/confetti-five.svg";
import { useTranslation } from "react-i18next";

const DURATION = 1 * 1000;

export const LiftoffStep = () => {
    const { t } = useTranslation();

    const animationEnd = Date.now() + DURATION;
	const colors = ["#f4c14b", "#256be9", "#49edaa", "#6112af"];
	// eslint-disable-next-line no-restricted-globals
	const particleCount = screen.width / 1000;

	(function frame() {
		confetti({
			particleCount,
			angle: 110,
			spread: 30,
			origin: { x: 1, y: 1 },
			colors: [colors[Math.floor(Math.random() * colors.length)]],
			startVelocity: 100,
		});

		confetti({
			particleCount,
			angle: 70,
			spread: 30,
			origin: { x: 0, y: 1 },
			colors: [colors[Math.floor(Math.random() * colors.length)]],
			startVelocity: 100,
		});

		if (Date.now() < animationEnd) {
			requestAnimationFrame(frame);
		}
	}());

    return (
        <>
            <Heading
                textAlign="center"
            >
                {t("makeChallengePage.challengeMade.title")}
            </Heading>
            <Text
                textAlign="center"
            >
                {t("makeChallengePage.challengeMade.subTitle")}
            </Text>
            <Image src={confettiFive} alt="Confitti Five" maxHeight="800px" margin="auto"/>
        </>
    );
};