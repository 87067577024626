import { Footer } from "components/footer/footer";
import { NavBar } from "components/navBar/navBar";
import { Outlet } from "react-router-dom";

export const Layout = () => (
    <>
        <NavBar />
        <Outlet />
        <Footer />
    </>
);