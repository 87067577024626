import { ArrowLeftIcon, ArrowRightIcon } from "@chakra-ui/icons";
import { Box, Button, Flex, Step, StepDescription, StepIcon, StepIndicator, StepNumber, StepSeparator, StepStatus, StepTitle, Stepper, useSteps } from "@chakra-ui/react";
import { GoalStep } from "components/challengeSteps/goalStep";
import { LiftoffStep } from "components/challengeSteps/liftoffStep";
import { OverviewStep } from "components/challengeSteps/overviewStep";
import { TitleStep } from "components/challengeSteps/titleStep";
import { TypeStep } from "components/challengeSteps/typeStep";
import { CreateOneChallengeMutationVariables } from "pages/makeChallenge/models/createOneChallenge";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { useRouteDefinitionGenerator } from "useRouteDefinitionGenerator";
import { DurationStep } from "../../components/challengeSteps/durationStep";
import { useGetOneChallengeQuery } from "./models/getOneChallenge";
import { useUpdateOneChallengeMutation } from "./models/updateOneChallenge";

const useQuery = () => {
    const { search } = useLocation();
  
    return useMemo(() => new URLSearchParams(search), [search]);
}

export const UpdateChallengePage = () => {
    const { t } = useTranslation();

    const navigate = useNavigate();
    const { getRoutes } = useRouteDefinitionGenerator();
    const routeDefinitions = getRoutes();

    const query = useQuery();

    const steps = t("updateChallengePage.steps", { returnObjects: true }) as {title: string, description: string, instructions: string}[];

    const challengeId = query.get(t("routes.updateChallenge.parameters.challengeId"));
    const { data: challengesData } = useGetOneChallengeQuery({ variables: { id: challengeId || "" }, skip: !challengeId});
    const initialChallengeData = challengesData && challengesData.challenges && challengesData.challenges.length ? challengesData.challenges[0] : null;

    useEffect(() => {
        setChallengeData({
            activeFrom: initialChallengeData?.activeFrom ? new Date(initialChallengeData.activeFrom) : new Date(),
            activeTo: initialChallengeData?.activeTo ? new Date(initialChallengeData.activeTo) : new Date(),
            classes: (initialChallengeData?.classes || []).map((classRoom) => (classRoom.id)),
            goal: initialChallengeData?.goal || 0,
            school: initialChallengeData?.school.id || "",
            scoringType: initialChallengeData?.scoringType || "",
            title: initialChallengeData?.title || ""
        });
    }, [initialChallengeData]);

    const [ updateOneChallengeMutation ] = useUpdateOneChallengeMutation();

    const [ challengeData, setChallengeData ] = useState<CreateOneChallengeMutationVariables>({
        activeFrom: "",
        activeTo: "",
        classes: [],
        goal: 0,
        school: "",
        scoringType: "",
        title: ""
    });

    const { activeStep, goToNext, goToPrevious } = useSteps({
        index: 0,
        count: steps.length,
    });

    const stepComponents: { fields: Array<keyof CreateOneChallengeMutationVariables>, element: (props: any) => JSX.Element }[] = [
        { fields: ["title"], element: TitleStep },
        { fields: ["activeFrom", "activeTo"], element: DurationStep },
        { fields: ["scoringType", "school", "classes"], element: TypeStep },
        { fields: ["goal"], element: GoalStep },
        { fields: [], element: OverviewStep },
        { fields: [], element: LiftoffStep }
    ];

    const ActiveStepComponent = stepComponents[activeStep].element;
    const activeChallengeDataFields = stepComponents[activeStep].fields;

    const setDataValue = (fieldNames: Array<keyof CreateOneChallengeMutationVariables>, fieldValues: (string | number | string[])[] | (string | number | string[])) => {
        const adaptedState = { ...challengeData };

        if (!Array.isArray(fieldValues)) {
            fieldValues = [fieldValues];
        }

        for (const [index, fieldName] of fieldNames.entries()) {
            adaptedState[fieldName] = fieldValues[index] as never;
        }

        setChallengeData(adaptedState);
    };

    const getDataValue = (fieldNames: Array<keyof CreateOneChallengeMutationVariables> | keyof CreateOneChallengeMutationVariables) => {
        if (!Array.isArray(fieldNames)) {
            const fieldName = fieldNames as keyof CreateOneChallengeMutationVariables;

            return challengeData[fieldName];
        }

        const dataToReturn = [];
        for (const fieldName of fieldNames) {
            dataToReturn.push(challengeData[fieldName]);
        }

        return dataToReturn;
    };

    const getCurrentStepElement = () => {
        return (
            <ActiveStepComponent 
                title={steps[activeStep]?.title} 
                description={steps[activeStep]?.description} 
                instructions={steps[activeStep]?.instructions} 
                onChange={(value: (string | number | string[])) => (setDataValue(activeChallengeDataFields, value)) } 
                challengeData={challengeData}
                defaultValue={getDataValue(activeChallengeDataFields)}
            /> 
        );
    };

    useEffect(() => {
        if (activeStep === steps.length) {
            updateOneChallengeMutation({ variables: {
                ...challengeData,
                id: challengeId || ""
            } });
        }
    }, [activeStep]);

    return (
        <>
            <Stepper 
                size="lg" 
                index={activeStep}
                marginBottom="10px"
            >
                {steps.map((step, index) => (
                    <Step key={index}>
                        <StepIndicator>
                            <StepStatus
                                complete={<StepIcon />}
                                incomplete={<StepNumber />}
                                active={<StepNumber />}
                            />
                        </StepIndicator>

                        <Box flexShrink="0">
                            <StepTitle>{step.title}</StepTitle>
                            <StepDescription hidden={index !== activeStep}>{step.description}</StepDescription>
                        </Box>

                        <StepSeparator />
                    </Step>
                ))}
            </Stepper>
            <Flex
                flexDirection="column"
                justifyContent="center"
                maxWidth="50vw"
                minHeight="80vh"
                margin="auto"
            >
                <Box
                    minHeight="100%"
                >
                    { 
                        getCurrentStepElement()
                    }
                </Box>
                <Flex
                    position="fixed"
                    bottom="10px"
                    left="0"
                    width="100%"
                    justifyContent="space-between"
                    padding="10px"
                    paddingLeft="25%"
                    paddingRight="25%"
                >
                    <Button
                        variant="solid"
                        onClick={() => {
                            if (activeStep !== steps.length) {
                                goToPrevious();
                            }
                        }}
                        disabled={activeStep === steps.length || activeStep === 0}
                        cursor={activeStep === steps.length || activeStep === 0 ? "not-allowed" : "pointer"}
                    >
                        <ArrowLeftIcon 
                            paddingRight="4px"
                        />
                        {t("makeChallengePage.previousStep")}
                    </Button>
                    <Button
                        variant="solid"
                        onClick={() => {
                            if (activeStep !== steps.length) {
                                goToNext();
                                return;
                            }

                            navigate(routeDefinitions.index);
                        }}
                    >
                        {t("makeChallengePage.nextStep")}
                        <ArrowRightIcon 
                            paddingLeft="4px"
                        />
                    </Button>
                </Flex>
            </Flex>
        </>
    );
};