import { Background } from "components/background/background";
import { Layout } from "components/layout/layout";
import { event } from "event";
import { ChallengeOverviewPage } from "pages/challengeOverview/challengeOverview";
import { MakeChallengePage } from "pages/makeChallenge/makeChallengePage";
import { useEffect } from "react";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import { useRouteDefinitionGenerator } from "useRouteDefinitionGenerator";
import { usePageTitleSetter } from "utils/pageTitleSetter";
import { HomePage } from "pages/home/homePage";
import { UpdateChallengePage } from "pages/updateChallenge/updateChallengePage";

export interface ApplicationRoutes {
    index: string;
    challengeOverview: string;
    makeChallenge: string;
    updateChallenge: string;
};

export const Content = () => {
    const { getRoutes } = useRouteDefinitionGenerator();
    const routeDefinitions = getRoutes();

    const { usePageTitleEvents } = usePageTitleSetter({ routes: routeDefinitions });
    usePageTitleEvents();

    const location = useLocation();
    useEffect(() => {
        if (location) {
            event.emit("routeChanged", location.pathname);

        }
    }, [location]);

    return (
        <Routes>
            <Route element={<Layout />}>
                <Route path="*" element={<Navigate to={routeDefinitions.index} />} />
                <Route path="/" element={<HomePage />} />
                <Route element={<Background />}>
                    <Route path={routeDefinitions.challengeOverview} element={<ChallengeOverviewPage />} />
                    <Route path={routeDefinitions.makeChallenge} element={<MakeChallengePage />} />
                    <Route path={routeDefinitions.updateChallenge} element={<UpdateChallengePage />} />
                </Route>
            </Route>
        </Routes>
    );
};
