import { Box, Heading, Input, Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay, Text, useDisclosure } from "@chakra-ui/react";
import { useEffect, useRef, useState } from "react";
import { Trans } from "react-i18next";

interface Props {
    title: string;
    description: string;
    instructions: string;

    defaultValue?: string;

    onChange: (value: string) => void;
}

export const TitleStep = (props: Props) => {
    const { description, instructions, defaultValue, onChange } = props;

    const [value, setValue] = useState(defaultValue || "");
    

    const { isOpen, onOpen, onClose } = useDisclosure();
    const finalRef = useRef(null);

    useEffect(() => {
        if (defaultValue) {
            setValue(defaultValue);
        }
    }, [defaultValue]);

    return (
        <>
            <Heading 
                as="h3"
                marginBottom="10px"
            >
                { description }
            </Heading>
            <Input 
                placeholder={instructions} 
                size="lg" 
                marginBottom="10px"
                ref={finalRef}
                value={value}
                onChange={(e) => {
                    setValue(e.target.value);
                    onChange(e.target.value);
                }}
            />
            <Text 
                fontSize="xs"
                cursor="pointer"
                onClick={onOpen}
            >
                Inspiratie nodig? Klik hier!
            </Text>

            <Modal 
                finalFocusRef={finalRef} 
                isOpen={isOpen} 
                onClose={onClose} 
                isCentered
                size="xl"
            >
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>
                        <Trans i18nKey="makeChallengePage.inspirationModal.title" />
                    </ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <Box
                            paddingLeft="10px"
                            paddingRight="10px"
                        >
                            <Trans i18nKey="makeChallengePage.inspirationModal.content" components={{ ul: <ul/>, li: <li/> }} />
                        </Box>
                    </ModalBody>
                </ModalContent>
            </Modal>
        </>
    );
};