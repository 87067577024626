import {
    Heading,
    NumberDecrementStepper,
    NumberIncrementStepper,
    NumberInput,
    NumberInputField,
    NumberInputStepper,
    Text,
} from "@chakra-ui/react";


interface Props {
    title: string;
    description: string;
    instructions: string;

    defaultValue: number;

    onChange: (value: number) => void;
}

export const GoalStep = (props: Props) => {
    const { description, instructions, defaultValue, onChange } = props;

    return (
        <>
            <Heading 
                as="h3"
                marginBottom="10px"
            >
                { description }
            </Heading>
            <Text>
                { instructions }
            </Text>
            <NumberInput 
                min={0} 
                size="lg"
                defaultValue={defaultValue || 1000}
                onChange={(_, value) => onChange(value)}
            >
                <NumberInputField />
                <NumberInputStepper>
                    <NumberIncrementStepper />
                    <NumberDecrementStepper />
                </NumberInputStepper>
            </NumberInput>
        </>
    );
};