/* eslint-disable */
import * as Types from '../../../models/schema.d';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetClassRoomQueryVariables = Types.Exact<{
  id: Types.Scalars['String']['input'];
}>;


export type GetClassRoomQuery = { __typename?: 'Query', classes: Array<{ __typename?: 'Class', id: string, title: string, school: { __typename?: 'School', id: string, title: string } }> };


export const GetClassRoomDocument = gql`
    query getClassRoom($id: String!) {
  classes(filter: {id: {equals: $id}}, sort: [{order: ASC, field: "title"}]) {
    id
    title
    school {
      id
      title
    }
  }
}
    `;

/**
 * __useGetClassRoomQuery__
 *
 * To run a query within a React component, call `useGetClassRoomQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClassRoomQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClassRoomQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetClassRoomQuery(baseOptions: Apollo.QueryHookOptions<GetClassRoomQuery, GetClassRoomQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetClassRoomQuery, GetClassRoomQueryVariables>(GetClassRoomDocument, options);
      }
export function useGetClassRoomLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetClassRoomQuery, GetClassRoomQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetClassRoomQuery, GetClassRoomQueryVariables>(GetClassRoomDocument, options);
        }
export type GetClassRoomQueryHookResult = ReturnType<typeof useGetClassRoomQuery>;
export type GetClassRoomLazyQueryHookResult = ReturnType<typeof useGetClassRoomLazyQuery>;
export type GetClassRoomQueryResult = Apollo.QueryResult<GetClassRoomQuery, GetClassRoomQueryVariables>;

      export interface PossibleTypesResultData {
        possibleTypes: {
          [key: string]: string[]
        }
      }
      const result: PossibleTypesResultData = {
  "possibleTypes": {
    "SearchItem": [
      "ChallengeSearchItem",
      "ClassSearchItem",
      "ExerciseSearchItem",
      "PupilSearchItem",
      "RewardSearchItem",
      "SchoolSearchItem"
    ]
  }
};
      export default result;
    