import nl from "assets/translations/nl.json";
import _i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

export const i18n = _i18n
	.use(LanguageDetector)
	.use(initReactI18next)
	.init({
		debug: true,
		fallbackLng: "nl",
		interpolation: {
			escapeValue: false,
		},
		resources: {
			nl: {
				translation: nl,
			},
		},
	});
