import { Box, Tooltip } from "@chakra-ui/react";
import { keyframes } from "@emotion/react";
import { useTranslation } from "react-i18next";

interface Props {
    isActive: boolean;
}

export const StatusIndicator = (props: Props) => {
    const { isActive } = props;

    const { t } = useTranslation();

    const activeColor = "green.500";
    const inactiveColor = "gray.400";
    const ringScaleMin = 0.33;
    const ringScaleMax = 0.66;

    const pulseRing = keyframes`
    0% {
      transform: scale(${ringScaleMin});
    }
    30% {
      transform: scale(${ringScaleMax});
    }
    40%, 50% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
	`

    const pulseDot = keyframes`
    0% {
    transform: scale(0.9);
    }
    25% {
    transform: scale(1.1);
    }
    50% {
    transform: scale(0.9);
    }
    100% {
    transform: scale(0.9);
    }
    `

    return (
        <Tooltip label={ isActive ?  t("challengeOverviewPage.challengeCard.active") : t("challengeOverviewPage.challengeCard.inactive")}>
            <Box
                as="div"
                h="24px"
                w="24px"
                mb="1.99em"
                position="absolute"
                right="-10px"
                top="-10px"
                bgColor={isActive ? activeColor : inactiveColor}
                borderRadius="50%"
                _before={isActive ? {
                    content: "''",
                    position: 'relative',
                    display: 'block',
                    width: '300%',
                    height: '300%',
                    boxSizing: 'border-box',
                    marginLeft: '-100%',
                    marginTop: '-100%',
                    borderRadius: '50%',
                    bgColor: activeColor,
                    animation: `2.25s ${pulseRing} cubic-bezier(0.455, 0.03, 0.515, 0.955) -0.4s infinite`,
                } : {}}
                _after={isActive ? {
                    animation: `2.25s ${pulseDot} cubic-bezier(0.455, 0.03, 0.515, 0.955) -0.4s infinite`,
                } : {}}
            />
        </Tooltip>
    )
};