import { Flex, Spinner } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ChallengeCard } from "./components/challengeCard";
import { useGetChallengesQuery } from "./models/getChallenges";

interface MappedChallenge {
    id: string;
    title: string;
    groupName: string;
    progress: number;
    activeFrom: Date;
    activeTo: Date;
}

export const ChallengeOverviewPage = () => {
    const { t } = useTranslation();
    const { data, loading } = useGetChallengesQuery();

    const [ mappedData, setMappedData ] = useState<MappedChallenge[]>([]);

    useEffect(() => {
        if (data && data.challenges && data.challenges.length > 0) {
            const mappedChallenges = [];

            for (const challenge of data.challenges) {
                const mappedChallenge: MappedChallenge = {
                    id: challenge.id,
                    title: challenge.title,
                    groupName: "",
                    progress: 0,
                    activeFrom: new Date(challenge.activeFrom),
                    activeTo: new Date(challenge.activeTo),
                };

                if (challenge.scoringType === "COMBINED") {
                    mappedChallenge.groupName = t(`challengeOverviewPage.challengeCard.challengeType.${challenge.scoringType}`, { schoolName: challenge.school.title })
                }

                if (challenge.scoringType === "SEPARATE") {
                    mappedChallenge.groupName = t(`challengeOverviewPage.challengeCard.challengeType.${challenge.scoringType}`, { className: challenge.classes[0].title })
                }

                const progressForGroup = challenge.progress[0];
                const { progress } = progressForGroup;

                mappedChallenge.progress = Math.round(Math.min((progress / challenge.goal) * 100, 100));

                mappedChallenges.push(mappedChallenge);
            }

            setMappedData(mappedChallenges);
        }
    }, [ data, t ]);

    if (loading) {
        return (
            <Flex justifyContent="center" alignItems="center" height="100vh">
                <Spinner size="xl" />
            </Flex>
        );
    }

    if (!mappedData.length) {
        return (
            <Flex
            justifyContent="center"
            flexWrap="wrap"
            >
                <h1>{t("challengeOverviewPage.noChallengesYet")}</h1>
            </Flex>
        );
    }

    return (
        <Flex
            justifyContent="center"
            flexWrap="wrap"
        >
            {
                mappedData.map((challenge) => (
                    <ChallengeCard 
                        id={challenge.id} 
                        title={challenge.title} 
                        groupName={challenge.groupName} 
                        progress={challenge.progress} 
                        activeFrom={challenge.activeFrom}
                        activeTo={challenge.activeTo}
                    />
                ))
            }
        </Flex>
    );
};