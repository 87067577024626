import { Box, keyframes, useColorModeValue } from "@chakra-ui/react"

interface Props {
    drawAttention?: boolean;
    disabled?: boolean;
    children: React.ReactNode;
}

const pulseKeyframes = keyframes`
  0% {
    box-shadow: 0 0 0 0px rgba(48, 129, 204, 1);
  }
  100% {
    box-shadow: 0 0 0 30px rgba(0, 0, 0, 0);
  }
`

export const OptionWrapper = (props: Props) => {
    const { drawAttention, disabled, children } = props
  
    return (
      <Box
        mb={4}
        shadow="base"
        borderWidth="1px"
        alignSelf={{ base: "center", lg: "flex-start" }}
        borderColor={useColorModeValue("gray.200", "gray.500")}
        borderRadius="xl"
        position="relative"
        minWidth="250px"
        animation={drawAttention ? `${pulseKeyframes} 2s infinite;` : ""}
        _before={disabled ? {
          content: "''",
          height: "100%",
          left: "0",
          position: "absolute",
          top: "0",
          width: "100%",
          "z-index": "10",
          "backdropFilter": "blur(4px)",
        } : {}}
      >
        {children}
      </Box>
    );
}