import { WebStorageStateStore } from "oidc-client-ts";
import { AuthProviderProps } from "react-oidc-context";

const getCountryUrl = (urls: {[domain: string]: string} | string) => {
    if(!urls) {
        return undefined;
    }

    if(typeof urls === "string") {
        return urls;
    }

    const countryCode = window.location.host.replace(/^.*\./,"");
    return urls[countryCode] || urls["be"] || urls[Object.keys(urls)[0]];
};

export const oidcConfig: AuthProviderProps = {
    authority: getCountryUrl(window?.appEnvironment?.OIDC_AUTHORITY),
    client_id: window?.appEnvironment?.OIDC_CLIENT_ID,
    redirect_uri: `${window.location.origin}/callback`,
    // post_logout_redirect_uri: `${window.location.origin}/callback`,
    userStore: new WebStorageStateStore({ store: window.localStorage }),
    automaticSilentRenew: true,
    response_type: "code",
    scope: "openid profile",
    response_mode: "query",
    prompt: "login",
    stateStore: new WebStorageStateStore({ store: window.localStorage }),
    revokeTokensOnSignout: true,
    onSigninCallback: (_user) => {
        window.location.replace("/");
    }
};