/* eslint-disable */
import * as Types from '../../../models/schema.d';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetChallengesCountQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetChallengesCountQuery = { __typename?: 'Query', challengesCount: number };


export const GetChallengesCountDocument = gql`
    query getChallengesCount {
  challengesCount
}
    `;

/**
 * __useGetChallengesCountQuery__
 *
 * To run a query within a React component, call `useGetChallengesCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetChallengesCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetChallengesCountQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetChallengesCountQuery(baseOptions?: Apollo.QueryHookOptions<GetChallengesCountQuery, GetChallengesCountQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetChallengesCountQuery, GetChallengesCountQueryVariables>(GetChallengesCountDocument, options);
      }
export function useGetChallengesCountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetChallengesCountQuery, GetChallengesCountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetChallengesCountQuery, GetChallengesCountQueryVariables>(GetChallengesCountDocument, options);
        }
export type GetChallengesCountQueryHookResult = ReturnType<typeof useGetChallengesCountQuery>;
export type GetChallengesCountLazyQueryHookResult = ReturnType<typeof useGetChallengesCountLazyQuery>;
export type GetChallengesCountQueryResult = Apollo.QueryResult<GetChallengesCountQuery, GetChallengesCountQueryVariables>;

      export interface PossibleTypesResultData {
        possibleTypes: {
          [key: string]: string[]
        }
      }
      const result: PossibleTypesResultData = {
  "possibleTypes": {
    "SearchItem": [
      "ChallengeSearchItem",
      "ClassSearchItem",
      "ExerciseSearchItem",
      "PupilSearchItem",
      "RewardSearchItem",
      "SchoolSearchItem"
    ]
  }
};
      export default result;
    