import { Container, useColorModeValue } from "@chakra-ui/react";
import { Outlet } from "react-router-dom";

export const Background = () => {
    const backgroundColor = useColorModeValue("white", "gray.800");

    return (
        <Container minWidth={"100vw"} minHeight={"100vh"} backgroundColor={backgroundColor}>
            <Outlet />
        </Container>
    );
};
