import {
    Box,
    Button,
    Flex,
    HStack, Heading,
    Stack,
    Text,
    VStack,
    useColorModeValue
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useRouteDefinitionGenerator } from "useRouteDefinitionGenerator";
import { OptionWrapper } from "./components/optionWrapper";
import styles from "./homePage.module.css";
import { useGetChallengesCountQuery } from "./models/getChallengesCount";

export const HomePage = () => {
    const { t } = useTranslation();

    const { getRoutes } = useRouteDefinitionGenerator();
    const routeDefinitions = getRoutes();

    const [motivation, setMotivation] = useState<string>();
    const navigate = useNavigate();

    const { data } = useGetChallengesCountQuery();

    useEffect(() => {
        const motivations = t("motivations", { returnObjects: true }) as string[];

        const randomIndex = Math.floor(Math.random() * motivations.length);
        setMotivation(motivations[randomIndex]); 
    }, [t]);

    const hasMadeAChallenge = data && data.challengesCount && data.challengesCount > 0;

    return (
        <div className={styles.page}>
            <Flex minHeight="100%" justifyContent="center" alignItems="center" direction="column">
                <VStack spacing={2} textAlign="center">
                    <Heading as="h1" fontSize="4xl">
                        { t("homePage.title") }
                    </Heading>
                    <Text fontSize="lg" color="gray.500" maxWidth="800px">
                    {motivation}
                    </Text>
                </VStack>
                <Stack
                    direction={{ base: "column", md: "row" }}
                    textAlign="center"
                    justify="center"
                    spacing={{ base: 4, lg: 10 }}
                    py={10}
                >
                    <OptionWrapper disabled={!hasMadeAChallenge}>
                        <Box py={4} px={12}>
                            <Text fontWeight="500" fontSize="2xl">
                                { t("homePage.editChallenge") }
                            </Text>
                            <HStack justifyContent="center">
                            <Text fontSize="5xl" fontWeight="900">
                                ✏️
                            </Text>
                            </HStack>
                        </Box>
                        <VStack
                            bg={useColorModeValue("gray.50", "gray.700")}
                            py={4}
                            borderBottomRadius="xl"
                        >
                            <Box w="80%" pt={7}>
                            <Button w="full" colorScheme="blue" variant="outline" onClick={() => navigate(routeDefinitions.challengeOverview)}>
                                { t("homePage.letsGo") }
                            </Button>
                            </Box>
                        </VStack>
                    </OptionWrapper>

                    <OptionWrapper drawAttention={!hasMadeAChallenge}>
                    <Box>
                        <Box py={4} px={12}>
                        <Text fontWeight="500" fontSize="2xl">
                            { t("homePage.makeChallenge") }
                        </Text>
                        <HStack justifyContent="center">
                            <Text fontSize="8xl" fontWeight="900">
                            💪
                            </Text>
                        </HStack>
                        </Box>
                        <VStack
                            bg={useColorModeValue("gray.50", "gray.700")}
                            py={4}
                            borderBottomRadius="xl"
                        >
                        <Box w="80%" pt={7}>
                            <Button w="full" colorScheme="blue" onClick={() => navigate(routeDefinitions.makeChallenge)}>
                                { t("homePage.letsGo") }
                            </Button>
                        </Box>
                        </VStack>
                    </Box>
                    </OptionWrapper>
                    <OptionWrapper disabled={!hasMadeAChallenge}>
                    <Box py={4} px={12}>
                        <Text fontWeight="500" fontSize="2xl">
                            { t("homePage.followChallenge") }
                        </Text>
                        <HStack justifyContent="center">
                        <Text fontSize="5xl" fontWeight="900">
                            👀
                        </Text>
                        </HStack>
                    </Box>
                    <VStack
                        bg={useColorModeValue("gray.50", "gray.700")}
                        py={4}
                        borderBottomRadius="xl"
                    >
                        <Box w="80%" pt={7}>
                        <Button w="full" colorScheme="blue" variant="outline" onClick={() => navigate(routeDefinitions.challengeOverview)}>
                            { t("homePage.letsGo") }
                        </Button>
                        </Box>
                    </VStack>
                    </OptionWrapper>
                </Stack>
            </Flex>
        </div>
    );
};