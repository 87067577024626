/* eslint-disable */
import * as Types from '../../../models/schema.d';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetOneChallengeQueryVariables = Types.Exact<{
  id: Types.Scalars['String']['input'];
}>;


export type GetOneChallengeQuery = { __typename?: 'Query', challenges: Array<{ __typename?: 'Challenge', id: string, activeFrom: any, activeTo: any, goal: number, scoringType: string, title: string, classes: Array<{ __typename?: 'Class', id: string }>, school: { __typename?: 'School', id: string } }> };


export const GetOneChallengeDocument = gql`
    query getOneChallenge($id: String!) {
  challenges(filter: {id: {equals: $id}}) {
    id
    activeFrom
    activeTo
    classes {
      id
    }
    goal
    school {
      id
    }
    scoringType
    title
  }
}
    `;

/**
 * __useGetOneChallengeQuery__
 *
 * To run a query within a React component, call `useGetOneChallengeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOneChallengeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOneChallengeQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetOneChallengeQuery(baseOptions: Apollo.QueryHookOptions<GetOneChallengeQuery, GetOneChallengeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetOneChallengeQuery, GetOneChallengeQueryVariables>(GetOneChallengeDocument, options);
      }
export function useGetOneChallengeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetOneChallengeQuery, GetOneChallengeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetOneChallengeQuery, GetOneChallengeQueryVariables>(GetOneChallengeDocument, options);
        }
export type GetOneChallengeQueryHookResult = ReturnType<typeof useGetOneChallengeQuery>;
export type GetOneChallengeLazyQueryHookResult = ReturnType<typeof useGetOneChallengeLazyQuery>;
export type GetOneChallengeQueryResult = Apollo.QueryResult<GetOneChallengeQuery, GetOneChallengeQueryVariables>;

      export interface PossibleTypesResultData {
        possibleTypes: {
          [key: string]: string[]
        }
      }
      const result: PossibleTypesResultData = {
  "possibleTypes": {
    "SearchItem": [
      "ChallengeSearchItem",
      "ClassSearchItem",
      "ExerciseSearchItem",
      "PupilSearchItem",
      "RewardSearchItem",
      "SchoolSearchItem"
    ]
  }
};
      export default result;
    