/* eslint-disable */
import * as Types from '../../../models/schema.d';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type DeleteOneChallengeMutationVariables = Types.Exact<{
  id: Types.Scalars['String']['input'];
}>;


export type DeleteOneChallengeMutation = { __typename?: 'Mutation', deleteOneChallenge: { __typename?: 'Success', success: boolean } };


export const DeleteOneChallengeDocument = gql`
    mutation deleteOneChallenge($id: String!) {
  deleteOneChallenge(id: $id) {
    success
  }
}
    `;
export type DeleteOneChallengeMutationFn = Apollo.MutationFunction<DeleteOneChallengeMutation, DeleteOneChallengeMutationVariables>;

/**
 * __useDeleteOneChallengeMutation__
 *
 * To run a mutation, you first call `useDeleteOneChallengeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteOneChallengeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteOneChallengeMutation, { data, loading, error }] = useDeleteOneChallengeMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteOneChallengeMutation(baseOptions?: Apollo.MutationHookOptions<DeleteOneChallengeMutation, DeleteOneChallengeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteOneChallengeMutation, DeleteOneChallengeMutationVariables>(DeleteOneChallengeDocument, options);
      }
export type DeleteOneChallengeMutationHookResult = ReturnType<typeof useDeleteOneChallengeMutation>;
export type DeleteOneChallengeMutationResult = Apollo.MutationResult<DeleteOneChallengeMutation>;
export type DeleteOneChallengeMutationOptions = Apollo.BaseMutationOptions<DeleteOneChallengeMutation, DeleteOneChallengeMutationVariables>;

      export interface PossibleTypesResultData {
        possibleTypes: {
          [key: string]: string[]
        }
      }
      const result: PossibleTypesResultData = {
  "possibleTypes": {
    "SearchItem": [
      "ChallengeSearchItem",
      "ClassSearchItem",
      "ExerciseSearchItem",
      "PupilSearchItem",
      "RewardSearchItem",
      "SchoolSearchItem"
    ]
  }
};
      export default result;
    