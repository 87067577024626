/* eslint-disable */
import * as Types from '../../../models/schema.d';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetClassRoomsQueryVariables = Types.Exact<{
  now: Types.Scalars['DateTime']['input'];
}>;


export type GetClassRoomsQuery = { __typename?: 'Query', classes: Array<{ __typename?: 'Class', id: string, title: string, school: { __typename?: 'School', id: string, title: string } }> };


export const GetClassRoomsDocument = gql`
    query getClassRooms($now: DateTime!) {
  classes(
    filter: {school: {schoolyear: {startsAt: {lte: $now}, endsAt: {gte: $now}}}}
    sort: [{order: ASC, field: "title"}]
  ) {
    id
    title
    school {
      id
      title
    }
  }
}
    `;

/**
 * __useGetClassRoomsQuery__
 *
 * To run a query within a React component, call `useGetClassRoomsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClassRoomsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClassRoomsQuery({
 *   variables: {
 *      now: // value for 'now'
 *   },
 * });
 */
export function useGetClassRoomsQuery(baseOptions: Apollo.QueryHookOptions<GetClassRoomsQuery, GetClassRoomsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetClassRoomsQuery, GetClassRoomsQueryVariables>(GetClassRoomsDocument, options);
      }
export function useGetClassRoomsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetClassRoomsQuery, GetClassRoomsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetClassRoomsQuery, GetClassRoomsQueryVariables>(GetClassRoomsDocument, options);
        }
export type GetClassRoomsQueryHookResult = ReturnType<typeof useGetClassRoomsQuery>;
export type GetClassRoomsLazyQueryHookResult = ReturnType<typeof useGetClassRoomsLazyQuery>;
export type GetClassRoomsQueryResult = Apollo.QueryResult<GetClassRoomsQuery, GetClassRoomsQueryVariables>;

      export interface PossibleTypesResultData {
        possibleTypes: {
          [key: string]: string[]
        }
      }
      const result: PossibleTypesResultData = {
  "possibleTypes": {
    "SearchItem": [
      "ChallengeSearchItem",
      "ClassSearchItem",
      "ExerciseSearchItem",
      "PupilSearchItem",
      "RewardSearchItem",
      "SchoolSearchItem"
    ]
  }
};
      export default result;
    