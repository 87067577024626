import { Heading, Table, Tbody, Td, Tr } from "@chakra-ui/react";
import { CreateOneChallengeMutationVariables } from "pages/makeChallenge/models/createOneChallenge";
import { useGetClassRoomQuery } from "pages/makeChallenge/models/getClassroom";
import { useGetSchoolQuery } from "pages/makeChallenge/models/getSchool";
import { useTranslation } from "react-i18next";
import { ChallengeType } from "./typeStep";

interface Props {
    description: string;
    challengeData: CreateOneChallengeMutationVariables
}

export const OverviewStep = (props: Props) => {
    const { description, challengeData } = props;

    const { t } = useTranslation();

    const { data: classRoom } = useGetClassRoomQuery({ variables: { id: challengeData.classes[0] }, skip: !challengeData || !challengeData.classes || !challengeData.classes.length || challengeData.scoringType !== ChallengeType.CLASS });
    const { data: school } = useGetSchoolQuery({ variables: { id: challengeData.school }, skip: !challengeData || !challengeData.school || challengeData.scoringType !== ChallengeType.SCHOOL });

    return (
        <>
            <Heading>{description}</Heading>
            <Table>
                <Tbody>
                    <Tr>
                        <Td>{t("makeChallengePage.overview.title")}</Td>
                        <Td>{challengeData.title}</Td>
                    </Tr>
                    <Tr>
                        <Td>{t("makeChallengePage.overview.activeFrom")}</Td>
                        <Td>{challengeData.activeFrom ? challengeData.activeFrom.toLocaleDateString() : ""}</Td>
                    </Tr>
                    <Tr>
                        <Td>{t("makeChallengePage.overview.activeTo")}</Td>
                        <Td>{challengeData.activeTo ? challengeData.activeTo.toLocaleDateString() : ""}</Td>
                    </Tr>
                    <Tr>
                        <Td>{t("makeChallengePage.overview.type")}</Td>
                        <Td>{t(`makeChallengePage.overview.types.${challengeData.scoringType}`)}</Td>
                    </Tr>
                    <Tr hidden={challengeData.scoringType !== ChallengeType.SCHOOL}>
                        <Td>{t("makeChallengePage.overview.school")}</Td>
                        <Td>{school && school.schools && school.schools.length ? school.schools[0].title : ""}</Td>
                    </Tr>
                    <Tr hidden={challengeData.scoringType !== ChallengeType.CLASS}>
                        <Td>{t("makeChallengePage.overview.class")}</Td>
                        <Td>{classRoom && classRoom.classes && classRoom.classes.length ? `${classRoom.classes[0].school.title} - ${classRoom.classes[0].title}` : ""}</Td>
                    </Tr>
                    <Tr>
                        <Td>Goal</Td>
                        <Td>{challengeData.goal}</Td>
                    </Tr>
                </Tbody>
            </Table>
        </>
    );
};