// This is a strictly typed version of the default NodeJS EventEmitter.
import EventEmitter from "events";
import { useEffect } from "react";
import TypedEventEmitter from "typed-emitter";

export type APIEvents = {
	mutationSucceeded: (message: string) => void
	mutationFailed: (error: Error) => void
}

export type RouteEvents = {
	routeChanged: (route: string) => void
}

export type Events = APIEvents & RouteEvents;
export type EventName = keyof Events;

/**
 * Allows to emit events and listen to them. This allows us to chain different Interaction Domains
 * together without explicitly depending on each other.
 */
export const event = new EventEmitter() as TypedEventEmitter<Events>

export const useEvent = (
	eventName: EventName, 
	listener: Events[EventName]
): void => {
	useEffect(() => {
		event.on(eventName, listener);

		return () => {
			event.removeListener(eventName, listener);
		};
	}, [eventName, listener]);
};
