import { Avatar, Box, Flex, Stack, Text, useColorModeValue } from "@chakra-ui/react";
import { ReactNode } from "react";

interface Props {
    title: string;
    icon: string;
    instructions: string | ReactNode;
    onClick?: () => void;
    isActive?: boolean;
}

export const ChallengeTypeBox = (props: Props) => {
    const { icon, title, instructions, onClick, isActive } = props;

    const boxBorderColor = useColorModeValue("gray.200", "gray.600");

    return (
        <Box
            borderColor={boxBorderColor}
            borderStyle="solid"
            borderRadius="10px"
            borderWidth="1px"
            padding="10px"
            minWidth="50%"
            _hover={{
                backgroundColor: boxBorderColor
            }}
            backgroundColor={(isActive && boxBorderColor) || ""}
            onClick={onClick}
        >
            <Stack
                    bg={useColorModeValue("white", "gray.800")}
                    boxShadow={"lg"}
                    p={8}
                    rounded={"xl"}
                    align={"center"}
                    pos={"relative"}
                    _after={{
                    content: `""`,
                    w: 0,
                    h: 0,
                    borderLeft: "solid transparent",
                    borderLeftWidth: 16,
                    borderRight: "solid transparent",
                    borderRightWidth: 16,
                    borderTop: "solid",
                    borderTopWidth: 16,
                    borderTopColor: useColorModeValue("white", "gray.800"),
                    pos: "absolute",
                    bottom: "-16px",
                    left: "50%",
                    transform: "translateX(-50%)"
                    }}
            >
                <Text
                    textAlign={"center"}
                    color={useColorModeValue("gray.600", "gray.400")}
                    fontSize={"sm"}
                >
                    { instructions }
                </Text>
            </Stack>
            <Flex
                align="center"
                mt={8}
                direction="column"
            >
                <Avatar src={icon} size="xl" />
                <Stack spacing={-1} align="center">
                    <Text fontWeight={600}>{title}</Text>
                </Stack>
            </Flex>
        </Box>
    );
};