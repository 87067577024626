/* eslint-disable */
import * as Types from '../../../models/schema.d';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateOneChallengeMutationVariables = Types.Exact<{
  activeFrom: Types.Scalars['DateTime']['input'];
  activeTo: Types.Scalars['DateTime']['input'];
  classes: Array<Types.Scalars['String']['input']> | Types.Scalars['String']['input'];
  goal: Types.Scalars['Int']['input'];
  school: Types.Scalars['String']['input'];
  scoringType: Types.Scalars['String']['input'];
  title: Types.Scalars['String']['input'];
}>;


export type CreateOneChallengeMutation = { __typename?: 'Mutation', createOneChallenge: { __typename?: 'Challenge', id: string } };


export const CreateOneChallengeDocument = gql`
    mutation createOneChallenge($activeFrom: DateTime!, $activeTo: DateTime!, $classes: [String!]!, $goal: Int!, $school: String!, $scoringType: String!, $title: String!) {
  createOneChallenge(
    data: {activeFrom: $activeFrom, activeTo: $activeTo, classes: $classes, goal: $goal, school: $school, scoringType: $scoringType, title: $title, description: ""}
  ) {
    id
  }
}
    `;
export type CreateOneChallengeMutationFn = Apollo.MutationFunction<CreateOneChallengeMutation, CreateOneChallengeMutationVariables>;

/**
 * __useCreateOneChallengeMutation__
 *
 * To run a mutation, you first call `useCreateOneChallengeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOneChallengeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOneChallengeMutation, { data, loading, error }] = useCreateOneChallengeMutation({
 *   variables: {
 *      activeFrom: // value for 'activeFrom'
 *      activeTo: // value for 'activeTo'
 *      classes: // value for 'classes'
 *      goal: // value for 'goal'
 *      school: // value for 'school'
 *      scoringType: // value for 'scoringType'
 *      title: // value for 'title'
 *   },
 * });
 */
export function useCreateOneChallengeMutation(baseOptions?: Apollo.MutationHookOptions<CreateOneChallengeMutation, CreateOneChallengeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateOneChallengeMutation, CreateOneChallengeMutationVariables>(CreateOneChallengeDocument, options);
      }
export type CreateOneChallengeMutationHookResult = ReturnType<typeof useCreateOneChallengeMutation>;
export type CreateOneChallengeMutationResult = Apollo.MutationResult<CreateOneChallengeMutation>;
export type CreateOneChallengeMutationOptions = Apollo.BaseMutationOptions<CreateOneChallengeMutation, CreateOneChallengeMutationVariables>;

      export interface PossibleTypesResultData {
        possibleTypes: {
          [key: string]: string[]
        }
      }
      const result: PossibleTypesResultData = {
  "possibleTypes": {
    "SearchItem": [
      "ChallengeSearchItem",
      "ClassSearchItem",
      "ExerciseSearchItem",
      "PupilSearchItem",
      "RewardSearchItem",
      "SchoolSearchItem"
    ]
  }
};
      export default result;
    