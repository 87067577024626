import { MoonIcon, SunIcon } from "@chakra-ui/icons";
import {
    Avatar,
    Box,
    Button,
    Center,
    Flex,
    Menu,
    MenuButton,
    MenuDivider,
    MenuItem,
    MenuList,
    Stack,
    useColorMode,
    useColorModeValue
} from "@chakra-ui/react";
import logo from "assets/images/Logo-HighFive.png";
import { useTranslation } from "react-i18next";
import { useAuth } from "react-oidc-context";
import { useNavigate } from "react-router-dom";
import { useRouteDefinitionGenerator } from "useRouteDefinitionGenerator";

export const NavBar = () => {
    const auth = useAuth();
    const { t } = useTranslation();

    const { getRoutes } = useRouteDefinitionGenerator();
    const routeDefinitions = getRoutes();

    const { colorMode, toggleColorMode } = useColorMode();
    const navigate = useNavigate();

    const isAuthorized = auth.isAuthenticated;

    const logout = () => {
		auth.removeUser();
		auth.signoutRedirect();
	};

    return (
        <Box bg={useColorModeValue("gray.100", "gray.900")} px={4} marginBottom={"10px"} >
            <Flex h={16} alignItems={"center"} justifyContent={"space-between"}>
            <Box onClick={() => navigate(routeDefinitions.index)} cursor={"pointer"}>
                <img src={logo} alt="Logo" width={150} />
            </Box>

            <Flex alignItems={"center"} hidden={!isAuthorized}>
                <Stack direction={"row"} spacing={7}>
                <Button onClick={toggleColorMode}>
                    {colorMode === "light" ? <MoonIcon /> : <SunIcon />}
                </Button>

                <Menu>
                    <MenuButton
                        as={Button}
                        rounded={"full"}
                        variant={"link"}
                        cursor={"pointer"}
                        minW={0}
                    >
                        <Avatar
                            size={"sm"}
                            src={"/apple-touch-icon.png"}
                        />
                    </MenuButton>
                    <MenuList alignItems={"center"}>
                    <br />
                    <Center>
                        <Avatar
                            size={"2xl"}
                            src={"/apple-touch-icon.png"}
                        />
                    </Center>
                    <MenuDivider />
                    <MenuItem
                        onClick={logout}
                    >
                        {t("navBar.logout")}
                    </MenuItem>
                    </MenuList>
                </Menu>
                </Stack>
            </Flex>
            </Flex>
        </Box>
    );
};
