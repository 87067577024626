import {
    Badge,
    Box,
    Button,
    Flex,
    Heading,
    Progress,
    Stack,
    Text,
    useColorModeValue
} from "@chakra-ui/react";
import { StatusIndicator } from "components/statusIndicator/statusIndicator";
import { ReactNode } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useRouteDefinitionGenerator } from "useRouteDefinitionGenerator";
import { useDeleteOneChallengeMutation } from "../models/deleteOneChallenge";

interface Props {
    id: string;
    title: string;
    groupName: string;
    progress: number;
    children?: ReactNode;
    activeFrom: Date;
    activeTo: Date;
}

export const ChallengeCard = (props: Props) => {
    const { id, title, groupName, progress, activeFrom, activeTo } = props;
    const cardBackgroundColor = useColorModeValue("white", "gray.900");
    const tagBackgroundColor = useColorModeValue("gray.50", "gray.800");

    const { t } = useTranslation();

    const navigate = useNavigate();
    const { getRoutes } = useRouteDefinitionGenerator();
    const routeDefinitions = getRoutes();

    const [ deleteOneChallengeMutation ] = useDeleteOneChallengeMutation({ refetchQueries: [ "getChallenges" ] });

    const now = new Date();
    const isActive = now >= activeFrom && now <= activeTo;

    const onDeleteClick = () => {
        deleteOneChallengeMutation({variables: { id }});
    };

    return (
        <Box
            maxW="500px"
            w="full"
            bg={cardBackgroundColor}
            boxShadow="2xl"
            rounded="lg"
            p={6}
            textAlign="center"
            margin="20px"
            key={id}
            position="relative"
        >
            <StatusIndicator
                isActive={isActive}
            />
            <Flex
                flexDirection="column"
                justifyContent="space-between"
                minHeight="100%"
            >
                <Heading 
                    fontSize="2xl" 
                    fontFamily="body"
                    overflow="hidden"
                    whiteSpace="nowrap"
                    textOverflow="ellipsis"
                >
                    {title}
                </Heading>
                <Text
                    fontSize="sm"
                >
                    {activeFrom.toLocaleDateString()} - {activeTo.toLocaleDateString()}
                </Text>

                <Stack align="center" justify="center" direction="row" wrap="wrap" mt={6} margin="20px">
                    <Badge
                        px={2}
                        py={1}
                        bg={tagBackgroundColor}
                        fontWeight="400">
                        {groupName}
                    </Badge>
                </Stack>

                <Flex
                    flexDirection="column"
                    justifyContent="space-between"
                >
                    <Progress value={progress} />
                    <Text>{progress}%</Text>
                </Flex>

                <Stack mt={8} direction={"row"} spacing={4}>
                    <Button
                        flex={1}
                        fontSize={"sm"}
                        rounded={"full"}
                        
                        variant="ghost"
                        onClick={onDeleteClick}
                    >
                        { t("challengeOverviewPage.challengeCard.delete") }
                    </Button>
                    <Button
                        flex={1}
                        fontSize={"sm"}
                        rounded={"full"}
                        onClick={() => (navigate(`${routeDefinitions.updateChallenge}?${t("routes.updateChallenge.parameters.challengeId")}=${id}`))}
                    >
                        { t("challengeOverviewPage.challengeCard.edit") }
                    </Button>
                </Stack>
            </Flex>
        </Box>
    );
};