import { Heading } from "@chakra-ui/react";
import { RangeDatepicker } from "chakra-dayzed-datepicker";
import { useState } from "react";

interface Props {
    title: string;
    description: string;

    defaultValue?: [Date, Date];

    onChange: (selectedDates: Date[]) => void;
}

export const DurationStep = (props: Props) => {
    const { description, defaultValue, onChange } = props;

    const [selectedDates, setSelectedDates] = useState<Date[]>(defaultValue || []);

    return (
        <>
            <Heading
                as="h3"
                marginBottom="10px"
            >
                { description }
            </Heading>
            <RangeDatepicker
                minDate={new Date()}
                selectedDates={selectedDates}
                onDateChange={(dates: Date[]) => {
                    setSelectedDates(dates);

                    if (dates.length === 2) {
                        onChange(dates);
                    }
                }}
            />
        </>
    );
};