import { ApolloProvider } from "@apollo/client"
import { ChakraProvider, ColorModeScript, Flex, Spinner } from "@chakra-ui/react"
import { client } from "client"
import "i18n"
import { StrictMode, useEffect, useState } from "react"
import * as ReactDOM from "react-dom/client"
import { BrowserRouter } from "react-router-dom"
import { Content } from "routes"
import reportWebVitals from "./reportWebVitals"
import * as serviceWorker from "./serviceWorker"
import { AuthProvider, hasAuthParams, useAuth } from "react-oidc-context"
import { oidcConfig } from "oidcConfig"


const container = document.getElementById("root");
if (!container) throw new Error('Failed to find the root element');
const root = ReactDOM.createRoot(container);

const App = () => {
	const auth = useAuth();

	const [hasTriedSignin, setHasTriedSignin] = useState(false);

	// automatically sign-in
	useEffect(() => {
		if (!hasAuthParams() && !auth.isAuthenticated && !auth.activeNavigator && !auth.isLoading && !hasTriedSignin) {
			// eslint-disable-next-line @typescript-eslint/no-floating-promises
			auth.signinRedirect();
			setHasTriedSignin(true);
		}
	}, [auth, hasTriedSignin]);

	if (auth.isLoading) {
		return (
			<div className="h-screen">
				<Flex justifyContent="center" alignItems="center" height="100vh">
                <Spinner size="xl" />
            </Flex>
			</div>
		);
	}

	return (
		<ApolloProvider client={client}>
      <BrowserRouter>
        <ChakraProvider>
          <ColorModeScript />
          <Content />
        </ChakraProvider>
      </BrowserRouter>
    </ApolloProvider>
	);
};

root.render(
  <StrictMode>
		<AuthProvider {...oidcConfig}>
			<App />
		</AuthProvider>
	</StrictMode>
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorker.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

