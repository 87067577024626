/* eslint-disable */
import * as Types from '../../../models/schema.d';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdateOneChallengeMutationVariables = Types.Exact<{
  id: Types.Scalars['String']['input'];
  activeFrom: Types.Scalars['DateTime']['input'];
  activeTo: Types.Scalars['DateTime']['input'];
  classes: Array<Types.Scalars['String']['input']> | Types.Scalars['String']['input'];
  goal: Types.Scalars['Int']['input'];
  school: Types.Scalars['String']['input'];
  scoringType: Types.Scalars['String']['input'];
  title: Types.Scalars['String']['input'];
}>;


export type UpdateOneChallengeMutation = { __typename?: 'Mutation', updateOneChallenge: { __typename?: 'Challenge', id: string } };


export const UpdateOneChallengeDocument = gql`
    mutation updateOneChallenge($id: String!, $activeFrom: DateTime!, $activeTo: DateTime!, $classes: [String!]!, $goal: Int!, $school: String!, $scoringType: String!, $title: String!) {
  updateOneChallenge(
    id: $id
    data: {activeFrom: $activeFrom, activeTo: $activeTo, classes: $classes, goal: $goal, school: $school, scoringType: $scoringType, title: $title, description: ""}
  ) {
    id
  }
}
    `;
export type UpdateOneChallengeMutationFn = Apollo.MutationFunction<UpdateOneChallengeMutation, UpdateOneChallengeMutationVariables>;

/**
 * __useUpdateOneChallengeMutation__
 *
 * To run a mutation, you first call `useUpdateOneChallengeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOneChallengeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOneChallengeMutation, { data, loading, error }] = useUpdateOneChallengeMutation({
 *   variables: {
 *      id: // value for 'id'
 *      activeFrom: // value for 'activeFrom'
 *      activeTo: // value for 'activeTo'
 *      classes: // value for 'classes'
 *      goal: // value for 'goal'
 *      school: // value for 'school'
 *      scoringType: // value for 'scoringType'
 *      title: // value for 'title'
 *   },
 * });
 */
export function useUpdateOneChallengeMutation(baseOptions?: Apollo.MutationHookOptions<UpdateOneChallengeMutation, UpdateOneChallengeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateOneChallengeMutation, UpdateOneChallengeMutationVariables>(UpdateOneChallengeDocument, options);
      }
export type UpdateOneChallengeMutationHookResult = ReturnType<typeof useUpdateOneChallengeMutation>;
export type UpdateOneChallengeMutationResult = Apollo.MutationResult<UpdateOneChallengeMutation>;
export type UpdateOneChallengeMutationOptions = Apollo.BaseMutationOptions<UpdateOneChallengeMutation, UpdateOneChallengeMutationVariables>;

      export interface PossibleTypesResultData {
        possibleTypes: {
          [key: string]: string[]
        }
      }
      const result: PossibleTypesResultData = {
  "possibleTypes": {
    "SearchItem": [
      "ChallengeSearchItem",
      "ClassSearchItem",
      "ExerciseSearchItem",
      "PupilSearchItem",
      "RewardSearchItem",
      "SchoolSearchItem"
    ]
  }
};
      export default result;
    