import {
    Box,
    Flex,
    Text,
    useColorModeValue
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";

export const Footer = () => {
    const { t } = useTranslation();

    return (
        <Box
            bg={useColorModeValue("gray.50", "gray.900")}
            color={useColorModeValue("gray.700", "gray.200")}>
            <Flex
                flexDirection="row"
                justifyContent="space-evenly"
                alignItems="center"
            >
                <Text alignSelf="center" margin="10px">
                    {t("footer.copyright")} <a href="mailto:hallo@ikhighfive.be">hallo@ikhighfive.be</a>
                </Text>
            </Flex>
        </Box>
      )
};